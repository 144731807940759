import { useEffect } from 'react';
import { v4 } from 'uuid';

import {
    ClientUser,
    VideoAgentSession,
    getMockPreviousContext,
    getNewAgentTopic,
    getNewVideoAgentSessionJSON,
    pseudoUniqueParticipantIdSuffix,
} from '@spinach-shared/models';
import { isLocalStage } from '@spinach-shared/utils';

import { getUser, useGlobalNullableVideoAgent } from '../../..';

const sampleLogos = [
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/67752d7ef102500001d38250/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/676162cf3c83d2000153e9b3/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6732c23a5d9f7500018bdb97/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/675dab8a0c842a00011e410d/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/672130bc9a76cc0001e3fd4b/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66ea680269de0a000171cb13/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/67693babaa352b0001f1539f/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6762a0656a8a4900012f2965/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/675d129c9ef6180001d7e1fe/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6713c77e475e690001ca7895/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/675edca56c02c400011ebd6f/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66e7b06b960c6400019a0710/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/677e20d75a52390001def160/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66d0bcccded9890001e9c676/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/677824093136a00001cee754/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/675db6b3dc85f400013867ae/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/673043ff10f0c50001c9773e/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/674548a9ecde490001aab94b/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/65aa7678a9e6440001d02f80/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/67306014a8dae00001f227a0/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6774cc69a6877a0001e395ba/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/672b4d6ecb669a00014a8ad2/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6661458bdc928e0001abbdc1/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/674bfe2fba488e0001368784/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6736dcbb95d6af00017b012e/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6740ac98803d580001f93f0f/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c4e8d143cb9500013e1619/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6741499b6c2c8000013e8b98/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6753666f8acbb90001b36ab8/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/675b6442fe75cf000199aaef/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/677786c4449b6d000112034f/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6777d970cd0fb5000119f454/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6757df04a8e7ea0001ca36dc/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/67304ff8bd84b30001a3c2b1/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66e691fa1ca9880001c52ab6/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c2e4a0dc78e9000111f333/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/67325b0cba22eb0001c9adfc/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6730f3905a84f000013035cb/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6730f6ebeb72530001334a4a/picture',
];

export function useTestAgentSession({ botId, seriesId }: { botId: string; seriesId: string }) {
    const { session, setSession } = useGlobalNullableVideoAgent();
    useEffect(() => {
        async function exec() {
            const user = await getUser();
            const host = user.user ? new ClientUser(user.user) : undefined;
            console.log(host?.aiSubscriptionMetadata);
            const randomLogo = sampleLogos[Math.floor(Math.random() * sampleLogos.length)];
            const mockSession = getNewVideoAgentSessionJSON({
                botId,
                seriesId,
                hostId: host?._id || v4(),
                hostSubscriptionMetadata: host?.aiSubscriptionMetadata,
                pendingIntro: !!window.location.search?.includes('intro'),
                accountLogoUrl: randomLogo,
                meetingTitle: 'Daily Test Standup',
                agenda: {
                    currentTopicId: undefined,
                    topics: [
                        // comment to keep line broken for easy disabling
                        // getNewAgentTopic('Discussion Topic'),
                    ],
                },
                settings: {
                    isRoundtableEnabled: false,
                    isFeedbackCollectionEnabled: false,
                    isEnabledForChatCommandAudioOutput: true,
                    isVoicePrimaryCommandKind: false,
                    isAssistantEnabled: true,
                },
                // previousContext: getMockPreviousContext('coburn'),
                participants: [
                    { name: 'Coburn', pseudoUniqueId: `Coburn${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Kirill', pseudoUniqueId: `Kirill${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Matan', pseudoUniqueId: `Matan${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Josh', pseudoUniqueId: `Josh${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Lucas', pseudoUniqueId: `Lucas${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Oded', pseudoUniqueId: `Oded${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Yoav', pseudoUniqueId: `Yoav${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Derek', pseudoUniqueId: `Derek${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Karin', pseudoUniqueId: `Karin${pseudoUniqueParticipantIdSuffix}` },
                ],
                scheduledStartTime: new Date(Date.now() - 1000 * 60 * 5).toISOString(),
                scheduledEndTime: new Date(Date.now() + 1000 * 15).toISOString(),
            });

            const wrapper = new VideoAgentSession(mockSession);

            setSession(wrapper);
        }

        if (isLocalStage() && !session) {
            exec();
        }
    }, [botId, seriesId, session, setSession]);
}
